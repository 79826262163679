<template>
  <div>
    <b-table stacked :items="marketingData"></b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      'reportMarketing'
    ]),
    marketingData () {
      if (!this.reportMarketing.sources) {
        return
      }
      let sources = this.reportMarketing.sources,
      obj = {},
      res = []
      obj.dateSubmitted = this.$moment(this.reportMarketing.dateSubmitted).format('YYYY-MM-DD')
      obj.for = this.reportMarketing.reptYr + '/' + this.reportMarketing.reptMo
      obj.by = this.reportMarketing.user.fullName
      for (let i in sources) {
        obj[sources[i].source.name] = '$' + sources[i].cost + ' (' + sources[i].conversions + ' signups)'
      }
      obj.totalSpend = '$' + this.reportMarketing.totalSpend
      obj.comments = this.reportMarketing.comments || '- no comments -'
      res.push(obj)
      return res
    }
  },
  methods: {
    ...mapActions([
      'getReportsMarketing'
    ]),
    doGetReportsMarketing () {
      this.getReportsMarketing({
        id: this.$route.params.rptId
      })
    }
  },
  mounted () {
    this.doGetReportsMarketing()
  }
}
</script>
